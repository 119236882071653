import React from 'react';
import { Helmet } from 'react-helmet';
import config from '../../config/SiteConfig';
import Post from '../models/Post';

interface SEO {
  postNode: Post;
  postPath: string;
  postSEO: boolean;
  curPath?: string;
}

export const SEO = (props: SEO) => {
  const { postNode, postPath, postSEO, curPath } = props;
  // console.log('Cur Path ->', curPath);
  let title;
  let description;
  let image;
  let postURL;
  if (postSEO) {
    // const postMeta = postNode.frontmatter;
    title = postNode.title;
    description = postNode.description;
    if (postNode.image) {
      const {
        childImageSharp: {
          fluid: { src: imageSrc },
        },
      } = postNode.image;
      // if (imageSrc) {
      //   image = imageSrc;
      // } else {
      //   image = config.siteBanner;
      // }

      image = !!imageSrc ? imageSrc : config.siteBanner;
    }
    postURL = `${config.siteUrl}/${postPath}`;
  } else {
    title = config.siteTitle;
    description = config.siteDescription;
    image = config.siteBanner;
  }
  image = config.siteUrl + image;
  // const blogURL = config.siteUrl + config.pathPrefix;
  const blogURL = curPath ? `${config.siteUrl}/${curPath}` : config.siteUrl;
  let schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      '@id': blogURL,
      url: blogURL,
      name: title,
      alternateName: config.siteTitleAlt ? config.siteTitleAlt : '',
    },
  ];

  const authorsList = () => {
    const { authors = [] } = postNode;
    if (authors && authors.length > 0) {
      let authorsString = '';
      authors.map((author: { name: string }) => {
        if (author.name) {
          // if(authorsString.length === 0) {
          //   authorsString = author.name;
          // } else {
          //   authorsString = `${authorsString}, ${author.name}`
          // }
          authorsString =
            authorsString.length === 0 ? author.name : `${authorsString}, ${author.name}`;
        }
      });
    }
    return config.author;
  };

  if (postSEO && postURL !== 'https://blogs.codeparva.com/Design-tokens') {
    schemaOrgJSONLD = [
      {
        '@context': 'http://schema.org',
        '@type': 'BlogPosting',
        // @ts-ignore
        '@id': postURL,
        // @ts-ignore
        url: postURL,
        name: title,
        headline: title,
        image: {
          '@type': 'ImageObject',
          url: image,
        },
        description: config.siteDescription,
        datePublished: postNode.publishedAt,
        dateModified: postNode.publishedAt,
        author: {
          '@type': 'Organization',
          name: authorsList(),
        },
        publisher: {
          '@type': 'Organization',
          name: config.author,
          logo: {
            '@type': 'ImageObject',
            url: config.siteUrl + config.siteLogo,
          },
        },
        isPartOf: blogURL,
        mainEntityOfPage: {
          '@type': 'WebSite',
          '@id': blogURL,
        },
      },
    ];
  } else if (postSEO && postURL === 'https://blogs.codeparva.com/Design-tokens') {
    schemaOrgJSONLD = [
      {
        '@context': 'http://schema.org',
        '@type': 'BlogPosting',
        // @ts-ignore
        mainEntityOfPage: {
          '@type': 'WebPage',
          '@id': postURL,
        },
        // @ts-ignore
        url: postURL,
        name: title,
        headline: title,
        image: {
          '@type': 'ImageObject',
          url: image,
          height: 463,
          width: 700,
        },
        datePublished: postNode.publishedAt,
        dateModified: postNode.publishedAt,
        author: {
          '@type': 'Organization',
          name: authorsList(),
        },
        publisher: {
          '@type': 'Organization',
          name: config.author,
          logo: {
            '@type': 'ImageObject',
            url: config.siteUrl + config.siteLogo,
            width: 550,
            height: 60,
          },
        },
        description: config.siteDescription,
        isPartOf: blogURL,
      },
    ];
  }
  if (postURL !== 'https://blogs.codeparva.com/Design-tokens') {
    return (
      <Helmet>
        <html lang={config.siteLanguage} />
        <title>{config.siteTitle}</title>
        <meta name="description" content={description} />
        <meta name="image" content={image} />
        <link rel="canonical" href={postSEO ? postURL : blogURL} />
        <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script>
        <meta property="og:locale" content={config.ogLanguage} />
        <meta property="og:site_name" content={config.ogSiteName ? config.ogSiteName : ''} />
        <meta property="og:url" content={postSEO ? postURL : blogURL} />
        {postSEO ? <meta property="og:type" content="article" /> : null}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="fb:app_id" content={config.siteFBAppID ? config.siteFBAppID : ''} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={config.userTwitter ? config.userTwitter : ''} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:url" content={postSEO ? postURL : blogURL} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
      </Helmet>
    );
  }

  if (postURL === 'https://blogs.codeparva.com/Design-tokens') {
    return (
      <Helmet>
        <html lang={config.siteLanguage} />
        <title>{config.siteTitle}</title>
        <meta name="title" content="Design Tokens : UI/UX Design | CodeParva Blogs" />
        <meta
          name="description"
          content="Design Tokens offers a library of UI/UX design elements to help you create the perfect website. Learn how to submit your own designs or request custom tokens."
        />
        <meta name="image" content={image} />
        <link rel="canonical" href={postSEO ? postURL : blogURL} />
        <meta name="keywords" content="Design Tokens, UI/UX Design, Figma Design" />
        <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script>
        <meta property="og:locale" content={config.ogLanguage} />
        <meta property="og:site_name" content={config.ogSiteName ? config.ogSiteName : ''} />
        <meta property="og:url" content={postSEO ? postURL : blogURL} />
        {postSEO ? <meta property="og:type" content="article" /> : null}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={image} />
        <meta property="fb:app_id" content={config.siteFBAppID ? config.siteFBAppID : ''} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content={config.userTwitter ? config.userTwitter : ''} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:url" content={postSEO ? postURL : blogURL} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={image} />
      </Helmet>
    );
  }
};
