import React, { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from '../theme/GlobalStyle';
import { Footer } from './Footer';

import '../style/all.scss';
import { darkTheme, lightTheme } from '../theme';

const Layout = ({ children }: { children: any }) => {
  const stored = typeof window !== 'undefined' && localStorage.getItem('isDarkMode');
  const [isDarkMode] = useState(stored === 'true' ? true : false);

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <React.Fragment>
        <GlobalStyle />
        {children}
        <Footer />
      </React.Fragment>
    </ThemeProvider>
  );
};

export { Layout };
