const transitions = {
  normal: '0.5s',
};

const fontSize = {
  small: '0.9rem',
  big: '2.5rem',
  bigMobile: '1.5rem',
  bigTablet: '2rem',
};

export { transitions, fontSize };
