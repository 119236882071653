import React from 'react';
import styled from 'styled-components';
import { graphql, StaticQuery } from 'gatsby';
import { split } from 'lodash';

const FooterWrapper = styled.footer`
  text-align: center;
  padding: 3rem 0;

  span {
    font-size: 0.75rem;
  }
`;

interface DataProps {
  site: {
    siteMetadata: {
      title: string;
    };
    buildTime: string;
  };
}

const Footer = () => {
  const query = graphql`
    {
      site {
        buildTime(formatString: "DD.MM.YYYY")
      }
    }
  `;
  const render = (data: DataProps) => {
    return (
      <FooterWrapper>
        &copy; {split(data.site.buildTime, '.')[2]} by{' '}
        <a
          href="https://codeparva.com"
          target="blank"
          title="CodeParva Technologies Pvt Ltd"
          rel="noopener"
        >
          CodeParva Technologies Pvt Ltd.{' '}
        </a>{' '}
        All rights reserved. <br /> <br />
      </FooterWrapper>
    );
  };

  return <StaticQuery query={query} render={render} />;
};

export { Footer };
