import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Subline } from './Subline';
import moment from 'moment';
import { media } from '../utils/media';
import GatsbyImage from 'gatsby-image';

const Post = styled.article`
  display: flex;
  flex-direction: column;
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
`;

const Title = styled.h2`
  position: relative;
  text-shadow: 0 1.2rem 3rem rgba(0, 0, 0, 0.15);
  margin-bottom: 0.75rem;
`;

const Initiale = styled.span`
  position: absolute;
  font-size: 7rem;
  transform: translate(-50%, -50%);
  opacity: 0.08;
  user-select: none;
  z-index: -1;
  @media ${media.tabletLandscape} {
    transform: translate(-35%, -50%);
  }
  @media ${media.tabletPortrait} {
    transform: translate(-35%, -50%);
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  @media ${media.tablet} {
    flex: 1;
    flex-direction: column;
  }
  @media ${media.phone} {
    flex: 1;
    flex-direction: column;
  }
`;

const ImageCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.2;
  justify-content: center;
  align-items: center;
  margin: auto 18px;
  @media ${media.tablet} {
    flex: 1;
  }
  @media ${media.phone} {
    flex: 1;
  }
`;

const ContentCol = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0.8;
  margin: auto 18px;
  @media ${media.tablet} {
    flex: 1;
  }
  @media ${media.phone} {
    flex: 1;
  }
`;

const Excerpt = styled.p`
  grid-column: -1 / 1;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-overflow: ellipsis;
`;

interface Props {
  title: string;
  date: string;
  excerpt: string;
  slug: string;
  thumbnail: any;
}

const Article = ({ title, date, excerpt, slug, thumbnail }: Props) => {
  const firstChar = title.charAt(0);
  return (
    <Post>
      <Row>
        {thumbnail && (
          <ImageCol>
            <GatsbyImage fluid={thumbnail.childImageSharp.fluid} style={{ width: '100%' }} />
          </ImageCol>
        )}
        <ContentCol>
          <Title>
            <Initiale>{firstChar}</Initiale>
            <Link to={`/${slug}`}>{title}</Link>
          </Title>
          <Subline>
            {moment(date).format('DD MMM, YYYY')}
            {/*<Link to={`/categories/${kebabCase(category)}`}> {category}</Link>*/}
          </Subline>
          <Excerpt>{excerpt}</Excerpt>
        </ContentCol>
      </Row>
    </Post>
  );
};

export { Article };
