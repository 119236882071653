const sizes = {
  tablet: '1200px',
  phone: '600px',
  laptop: '1280px',
};

export const media = {
  laptop: ` (min-width: ${sizes.tablet}) and (max-width: ${sizes.laptop})`,
  tablet: `(max-width: ${sizes.tablet})`,
  tabletPortrait: `(max-width: ${sizes.tablet}) and (orientation: portrait)`,
  tabletLandscape: `(max-width: ${sizes.tablet}) and ( orientation: landscape)`,
  phone: `(max-width: ${sizes.phone})`,
};
