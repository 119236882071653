export default {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"

  siteTitle: 'CodeParva Blogs', // Navigation and Site Title
  siteTitleAlt: 'Codeparva - All blogs and technical articles', // Alternative Site title for SEO
  siteUrl: 'https://blogs.codeparva.com', // Domain of your site. No trailing slash!
  siteLanguage: 'en', // Language Tag on <html> element
  siteBanner: '/assets/bg.png', // Your image for og:image tag. You can find it in the /static folder
  defaultBg: '/assets/bg.png',
  defaultPostBg: '/assets/defPostBg.png', // default post background header
  favicon: 'favicon.ico', // Your image for favicons. You can find it in the /src folder
  siteDescription: 'Collection of all our blogs about Self Storage Industry,' +
    'Technical Stacks and other such discussions', // Your site description
  author: 'CodeParva Technologies Pvt Ltd', // Author for schemaORGJSONLD
  siteLogo: '/assets/cp-logo.svg', // Image for schemaORGJSONLD
  siteLogoWhite: '/assets/cp-logo-white-icon.svg', // Image for schemaORGJSONLD

  // siteFBAppID: '123456789', // Facebook App ID - Optional
  userTwitter: '@codeparva', // Twitter Username - Optional
  ogSiteName: 'codeparva', // Facebook Site Name - Optional
  ogLanguage: 'en_US', // Facebook Language

  // Manifest and Progress color
  // See: https://developers.google.com/web/fundamentals/web-app-manifest/
  themeColor: '#8fd4e6',
  backgroundColor: '#2b2e3c',

  // Settings for typography.ts
  headerFontFamily: 'Bitter',
  bodyFontFamily: 'Open Sans',
  baseFontSize: '18px',

  // Social media
  siteFBAppID: '',

  //
  Google_Tag_Manager_ID: 'GTM-MJZHVRT',
  POST_PER_PAGE: 4,
};
